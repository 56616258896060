import { useState, ReactElement, useEffect } from 'react';
import { useIntl } from 'react-intl';
import messages from '../locales/messages';
import 'src/common/style/common.scss';
import { ThanksModal, thanksTable } from 'src/common/utils/ThanksModal';

export interface thanksProps {
  preference: string;
  kindService: string;
  stdLiceses: number;
  preLicenses: number;
  monthlyCost: string;
  bpName: string;
}

const Thanks = ({
  preference,
  kindService,
  stdLiceses,
  preLicenses,
  monthlyCost,
  bpName,
}: thanksProps): ReactElement => {
  const intl = useIntl();
  const [, setShowThanksModal] = useState(true);
  const titleThanks: thanksTable[] = [
    { group: 'title', value: intl.formatMessage(messages.thank_you) },
    {
      group: 'subTitle',
      value: intl.formatMessage(messages.thankyou_received),
    },
  ];

  const widgetTitleThanks: thanksTable[] = [
    {
      group: 'widgetTitle1',
      value: intl.formatMessage(messages.thanyou_req_detail),
    },
  ];

  const contentThanks: thanksTable[] = [
    {
      group: String(intl.formatMessage(messages.thanyou_preferences)),
      value: preference,
    },
    {
      group: String(intl.formatMessage(messages.thanyou_kind)),
      value: kindService,
    },
    {
      group: String(intl.formatMessage(messages.thankyou_stdLicenses)),
      value: String(stdLiceses),
    },
    {
      group: String(intl.formatMessage(messages.thankyou_preLicenses)),
      value: String(preLicenses),
    },
    {
      group: String(intl.formatMessage(messages.thankyou_monthlyCost)),
      value: monthlyCost,
    },
  ];

  bpName &&
    contentThanks.push({
      group: String(intl.formatMessage(messages.thanyou_bp_title)),
      value: bpName,
    });

  const buttonThanks: thanksTable[] = [
    {
      group: 'button1',
      value: String(intl.formatMessage(messages.thankyou_return_est_text)),
    },
  ];

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setShowModal(true);
  }, [monthlyCost, stdLiceses, preLicenses]);
  return (
    <ThanksModal
      showThanksModal={showModal}
      pageTitle="Cognos Price Estimator Page"
      object="Pricing Page"
      productTitle={'Cognos Price Estimator'}
      pageUrl={'/products/cognos-analytics/pricing/'}
      location={'Estimator'}
      titleThanks={titleThanks}
      widgetTitleThanks={widgetTitleThanks}
      contentThanks={contentThanks}
      buttonThanks={buttonThanks}
      setShowThanksModal={setShowThanksModal}
    />
  );
};

export default Thanks;
