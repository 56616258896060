// URX Embeded Form
// https://github.ibm.com/dgc-web-platform/widget-form

// Must be the div id where you want the form to be loaded
export const INSTANCE_ID = 'urx-form';

// The required urx form-id to be embedded for Planning Analytics
// export const FORM_ID = 'MAIL-planninganalyticsbp';
export const FORM_ID = 'QTE-dataaicognos';

// The required locale must be in the following format country code-language code
export const LOCALE = 'us-en';
