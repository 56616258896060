import { useState, useEffect } from 'react';
import lngToHtmlLang from '../../lib/lngToHtmlLang';

/**
 * Format price
 * @param price
 * @param numberFormat
 * @param currency
 * @returns
 */
const formatPrice = (
  price: number,
  numberFormat: Intl.NumberFormat,
  currency: string,
) => {
  const formattedValue = numberFormat.formatToParts(price);
  return formattedValue
    .map(({ type, value }) => (type === 'currency' ? currency + ' ' : value))
    .reduce((string, part) => string + part);
};

const formatCurrency = (
  price: number,
  numberFormat: Intl.NumberFormat,
  currency: string,
): string => {
  switch (currency) {
    case 'AUD':
    case 'CAD':
    case 'NZD':
    case 'USD':
      return formatPrice(price, numberFormat, currency);
    default: {
      return numberFormat.format(price);
    }
  }
};

export const useCurrency = (
  intl: string,
  lang: string,
  price: number,
): [string, React.Dispatch<React.SetStateAction<number>>] => {
  const [rawValue, setRawValue] = useState(price);
  const [formattedValue, setFormattedValue] = useState('');
  useEffect(() => {
    if (rawValue === null || intl == null || intl.length === 0) {
      setFormattedValue('');
    } else {
      const currency = intl;
      const numberFormat = new Intl.NumberFormat(lngToHtmlLang(lang), {
        style: 'currency',
        currency,
        currencyDisplay: 'symbol',
        minimumFractionDigits: 0,
      });
      const formatted = formatCurrency(rawValue, numberFormat, currency);
      setFormattedValue(formatted);
    }
  }, [lang, rawValue, intl]);
  return [formattedValue, setRawValue];
};

/**
 *
 * @param currency
 * @param rangeHigh
 * @returns
 */
export const removeSymbol = (currency: string, rangeHigh: string): string => {
  return rangeHigh.replace(currency, '');
};

/**
 *
 * @param currency
 * @param lang
 * @param amount
 * @returns
 */
export const formatLocalCurrency = (
  currency: string,
  lang: string,
  amount: number,
) => {
  const [value, setValue] = useCurrency(currency, lang, amount);
  return value;
};
