module.exports = {
  entry: './src/apps/cognos-price-estimator/index.tsx',
  shortcode: 'cognos-price-estimator',
  title: 'Cognos Price Estimator',
  status: 'stable', // stable or deprecated
  description: 'Allows users to determine a price.',
  preview: {
    url: `${process.env.PUBLIC_URL}/storybook/iframe.html?id=widgets-cognos-price-estimator-components--default&viewMode=story`,
  },
  useExternalPeerDependencies: ['react', 'react-dom', 'react-intl'],
  additionalCustomProperties: {
    webSegmentPaths: ['/cloud'],
    availableTranslations: [
      'de',
      'en',
      'es',
      'esla',
      'fr',
      'it',
      'ja',
      'ko',
      'pt',
      'zh-cn',
    ],
  },
};
