import { useEffect, useState } from 'react';
import { getListPrice } from 'src/common/hooks/widgets/fetchData';

export interface priceProps {
  ITEM_NUM: string;
  UNIT_PRIC: string;
  EXT_PRICE: string;
  PART_NUM: string;
}

const parts = {
  SaaS: [
    {
      PART_NUM: 'D20ENLL', //Standard
      type: 'license',
    },
    {
      PART_NUM: 'D20EQLL', //Premium
      type: 'license',
    },
  ],
};

interface QueryData {
  ITEM_TERM: number;
  ITEM_NUM: number;
  PART_NUM: string;
  QTY: number;
  BILL_FREQ: string;
}

export const partsToQuery: QueryData[] = [
  {
    ITEM_TERM: 1,
    ITEM_NUM: 0,
    PART_NUM: parts['SaaS'][0].PART_NUM,
    QTY: 0,
    BILL_FREQ: 'U',
  },
  {
    ITEM_TERM: 1,
    ITEM_NUM: 0,
    PART_NUM: parts['SaaS'][1].PART_NUM,
    QTY: 0,
    BILL_FREQ: 'U',
  },
];

export const setPrice = (
  standardLicense: number,
  premiumLicense: number,
  setPriceSaaS: React.Dispatch<React.SetStateAction<number>>,
  setSaaSYearlyPrice: React.Dispatch<React.SetStateAction<number>>,
  setStdPriceSaaS: React.Dispatch<React.SetStateAction<number>>,
  setPrmPriceSaaS: React.Dispatch<React.SetStateAction<number>>,
  productToAcquire: string,
  currency: string,
  country: string,
  userLocale: string,
  calculate: boolean,
) => {
  // Check language and prices
  const [priceFile, setPriceFile] = useState({} as priceProps[]);
  const [standarPrice, setStandardPrice] = useState(0);
  const [premiumPrice, setPremiumPrice] = useState(0);

  //Get price from street prices
  useEffect(() => {
    //Standard License
    partsToQuery[0].QTY = standardLicense;
    //Premium Licenses
    partsToQuery[1].QTY = premiumLicense;

    if (calculate) {
      const body: any = {
        HEADER_IN: {
          COUNTRY: country,
          CURRENCY: currency,
          FOR_STREET_PRICING: true,
        },
        ITEMS_IN: partsToQuery,
      };
      if (currency.length === 0 || country.length === 0) {
        setPriceFile([]);
        return;
      }
      getListPrice(
        `https://www.ibm.com/marketplace/purchase/catalog/resources/price/calculation`,
        body,
        'POST',
      )
        .then((data) => {
          if (data.HEADER_OUT.ERROR_FLAG === '0') {
            setPriceFile(data.ITEMS_OUT);
          } else {
            setPriceFile([]);
          }
        })
        .catch((err) => {
          console.log('Error:', err);
        });
    }
  }, [
    userLocale,
    currency,
    country,
    standardLicense,
    premiumLicense,
    calculate,
  ]);

  /**
   *
   */
  useEffect(() => {
    const price = determine_price(
      productToAcquire,
      priceFile,
      standardLicense,
      premiumLicense,
    );
    if (priceFile.length > 0 || price >= 0) {
      setPriceSaaS(price);
      setSaaSYearlyPrice(price * 12);
    }
  }, [priceFile, standarPrice, premiumPrice]);

  /**
   *
   */
  const determine_price = (
    product: string,
    priceFile: priceProps[],
    stdLicense: number,
    prmLicense: number,
  ) => {
    let price = 0;
    let tierPrice = 0;
    // Find product
    if (priceFile?.length) {
      const products = parts['SaaS'];
      products.forEach((partId) => {
        const estimatedPrice = priceFile.find(
          (i) => i.PART_NUM == partId.PART_NUM,
        );
        if (estimatedPrice) {
          tierPrice = estimatedPrice.EXT_PRICE
            ? parseInt(estimatedPrice.EXT_PRICE)
            : 0;
          console.log('price: ', tierPrice, ' for ', estimatedPrice.PART_NUM);
          estimatedPrice.PART_NUM === 'D20ENLL'
            ? setStandardPrice(tierPrice)
            : setPremiumPrice(tierPrice);
        }
      });
      setStdPriceSaaS(standarPrice);
      setPrmPriceSaaS(premiumPrice);
      price = standarPrice + premiumPrice;
    }
    return price;
  };
};
